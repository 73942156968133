<template>
    <div>
        <main v-if="!ready">
            <div class="d-flex flex-column mat24">
                <h2 class="text-center">
                    {{ say.results_are_loading }}
                </h2>
                <div class="mat16 lds-ellipsis lds-ellipsis--center">
                    <div />
                    <div />
                    <div />
                </div>
            </div>
        </main>
        <main v-if="ready">
            <div class="list-header">
                <!-- Select buttons. -->
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-12 col-lg-2 mar-auto">
                            <h1 class="l-short">
                                {{ say.results }}
                            </h1>
                            <p>{{ classification.namePl }}</p>
                        </div>
                        <div
                            v-if="ageCategories.length > 1"
                            class="col-12 col-lg-3"
                        >
                            <div class="list-header__select">
                                <base-input-select
                                    v-model="selectedAgeCategory"
                                    :options="ageCategories"
                                    :label="say.category"
                                    label-select="namePl"
                                />
                            </div>
                        </div>
                        <div
                            v-if="specialCategories.length > 0"
                            class="col-12 col-lg-3"
                        >
                            <div class="list-header__select">
                                <base-input-select
                                    v-model="selectedSpecialCategory"
                                    :options="specialCategories"
                                    :label="say.special_categories"
                                    label-select="namePl"
                                />
                            </div>
                        </div>
                        <div
                            class="col-12"
                            :class="{
                                'col-lg-2': Object.values(splitTime).length,
                                'col-lg-3': !Object.values(splitTime).length,
                            }"
                        >
                            <div class="list-header__search-input">
                                <base-input-text-with-icon
                                    v-model="search"
                                    :label="say.search"
                                    icon="fas fa-search"
                                    badge="fas fa-times"
                                    @icon-click="search = ''"
                                />
                            </div>
                        </div>
                        <div
                            v-if="Object.values(splitTime).length"
                            class="col-lg-1 list-header--right"
                        >
                            <div class="list-header__switch row">
                                <div
                                    class="list-header__switch-btn col-5"
                                    :class="{
                                        'list-header__switch-btn--right': isSplitTime,
                                    }"
                                    @click="isSplitTime = !isSplitTime"
                                >
                                    {{ isSplitTime ? "SPLIT" : "LAP" }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="palr16">
                <!-- Illustration remote competition. -->
                <div
                    v-if="competition.isRemote"
                    class="row"
                >
                    <div class="col-12">
                        <div class="bg--green results__remote">
                            <div class="row justify-content-start">
                                <div
                                    class="col-12 col-md-5 d-flex justify-content-center align-items-center"
                                >
                                    <img
                                        class="results__remote-img"
                                        src="@/assets/images/illustration-remote.svg"
                                        alt
                                    >
                                </div>
                                <div
                                    class="col-12 col-md-7 col-lg-5 results__remote-description"
                                >
                                    <div class="results__remote-header">
                                        {{ say.remote_competition }}
                                    </div>
                                    <div>{{ say.add_your_result_hint }}</div>
                                    <div
                                        v-if="
                                            canDeclareResults(user, competition)
                                        "
                                        class="row"
                                    >
                                        <div class="col-12 col-md-3 mat24">
                                            <base-button
                                                btn4
                                                class="nowrap"
                                                @click="declareResult()"
                                            >
                                                {{ say.add_your_result }}
                                            </base-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-if="!items.length"
                    class="row mat16"
                >
                    <div class="col-12 text-center">
                        {{ say.no_results_yet }}
                    </div>
                </div>
                <!-- Table. -->
                <div class="container-result">
                    <base-table
                        v-if="items.length"
                        class="table-list"
                        :headers="headers"
                        :items="items"
                        :search="search"
                    >
                        <template v-slot:header="{ sort }">
                            <tr class="table-list__row bg--white">
                                <th
                                    v-for="(header, column) in headers"
                                    :key="column"
                                    class="table-list__header"
                                    :class="{
                                        'table-list__header--second-cell':
                                            column === 1,
                                        'table-list__header--first-cell':
                                            column === 0,
                                        'd-none':
                                            column === 1 &&
                                            competition.isRemote &&
                                            competition.id !== 4877 ||
                                            header.value === 'meta_summary' && isSplitTime,
                                    }"
                                    @click="sort(column)"
                                >
                                    <template
                                        v-if="
                                            !isSplitTime &&
                                                column === headers.length - 3 &&
                                                !competition.isRemote
                                        "
                                    >
                                        LAP
                                    </template>
                                    <template v-else>
                                        {{ header.text }}
                                    </template>
                                </th>
                            </tr>
                        </template>

                        <template v-slot:body="{ displayedItems }">
                            <tr
                                v-for="item in displayedItems"
                                :key="item.id"
                                class="table-list__row"
                            >
                                <td
                                    v-for="(header, column) in headers"
                                    :key="column"
                                    class="table-list__data"
                                    :class="{
                                        'table-list__data--second-cell':
                                            column === 1,
                                        'table-list__data--first-cell':
                                            column === 0,
                                        'd-none':
                                            column === 1 &&
                                            competition.isRemote &&
                                            competition.id !== 4877 ||
                                            header.value === 'meta_summary' && isSplitTime,
                                        'table-list__data--right-border':
                                            column === 0 &&
                                            competition.isRemote &&
                                            competition.id !== 4877,
                                            
                                        'table-list__data--padding':
                                            header.value === 'type',
                                        'align-right':
                                            header.value === 'isDeclaredTime',
                                    }"
                                >
                                    <p v-if="header.value === 'meta_summary'">
                                        {{ item.time }}
                                    </p>                                
                                    <p
                                        v-else-if="
                                            header.value === 'isDeclaredTime' &&
                                                item.isDeclaredTime
                                        "
                                        class="table-list__data--info-time"
                                    >
                                        {{ say.declared_time }}
                                    </p>
                                    <p
                                        v-else-if="
                                            header.value ===
                                                'isDeclaredDistance' &&
                                                item.isDeclaredDistance
                                        "
                                        class="table-list__data--info-time"
                                    >
                                        {{ say.declared_distance }}
                                    </p>
                                    <p
                                        v-else-if="
                                            (header.value ===
                                                'isDeclaredTime' &&
                                                !item.isDeclaredTime) ||
                                                (header.value ===
                                                    'isDeclaredDistance' &&
                                                    !item.isDeclaredDistance)
                                        "
                                    />
                                    <template
                                        v-else-if="
                                            header.value === 'distance' &&
                                                item[header.value]
                                        "
                                    >
                                        {{ `${item[header.value]} km` }}
                                    </template>
                                    <template v-else>
                                        {{ item[header.value] }}
                                    </template>
                                </td>
                            </tr>
                        </template>

                        <template v-slot:no-results="{ search: term }">
                            <p class="bg--gray pa16">
                                {{ say.no_search_results }}
                                <b>{{ term }}</b>
                            </p>
                        </template>
                    </base-table>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
    BOOTSTRAP_MINISITE,
    READ_CLASSIFICATION,
    READ_CLASSIFICATION_PLAYERS,
    READ_CLASSIFICATION_CHECKPOINTS,
    READ_CLASSIFICATION_RESULTS_POSITIONS_OPEN,
    READ_CLASSIFICATION_RESULTS_SPLIT,
    READ_CLASSIFICATION_RESULTS_LAP,
    READ_DECLARE_RESULT_CONFIG,
} from '@/store/actions.type';
import actions from '@/mixins/actions';
import { insert } from '@/utils/array';
import moment from 'moment';
import { DNS_TIME, DNF_TIME, DSQ_TIME } from '@/config';

export default {
    mixins: [actions],
    props: {
        id: {
            type: Number,
            required: true,
        },
        classificationId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            ready: false,
            search: '',
            selectedAgeCategory: null,
            selectedSpecialCategory: null,
            headersDefault: [
                { text: '', value: 'position' },
                { text: 'Nr', value: 'number' },
                { text: 'Nazwisko i imię', value: 'name' },
                { text: 'Kraj', value: 'country' },
                { text: 'Miejscowość', value: 'city' },
                { text: 'Klub', value: 'clubname' },
                { text: '', value: 'isDeclaredTime' },
                { text: 'Czas', value: 'time' },
            ],
            positions: [],
            splitTime: {},
            lapTime: {},
            isSplitTime: true,
            remoteConfig: {},
        };
    },
    computed: {
        ...mapGetters([
            'say',
            'classifications',
            'user',
            'categoriesBy',
            'competitions',
            'playersBy',
            'minisiteId',
            'checkpointsBy',
            'results',
        ]),
        classification() {
            return this.classifications[this.classificationId];
        },
        competition() {
            return this.competitions[this.minisiteId];
        },
        categories() {
            return this.categoriesBy(this.classification);
        },
        ageCategories() {
            return this.categories.filter((category) => !category.special);
        },
        specialCategories() {
            return this.categories.filter((category) => category.special);
        },
        checkpoints() {
            const numberAsc = (a, b) => a.number - b.number;

            return this.checkpointsBy(this.classification).sort(numberAsc);
        },
        checkpointsHeaders() {
            if (!this.competition.isRemote) {
                const notStart = (checkpoint) => checkpoint.number > 0;

                return this.checkpoints.filter(notStart);
            }
            return [];
        },
        headers() {
            const checkpointsHeaders = this.checkpointsHeaders.map(
                (checkpoint) => ({
                    text: checkpoint.name,
                    value: `checkpoint_${checkpoint.id}`,
                }),
            );
            if (!this.competition.isRemote) {
                const headersDefaultWithoutTimeFiled = this.headersDefault.filter(
                    (header) => header.text !== 'Czas',
                );
                const meta = [{text: 'Meta', value: 'meta_summary'}]
                const checkpointsHeadersWithLap = insert(checkpointsHeaders, checkpointsHeaders.length, meta)

                return insert(
                    headersDefaultWithoutTimeFiled,
                    6,
                    checkpointsHeadersWithLap,
                );
            }
            if (!this.remoteConfig.isTime) {
                const timeHeader = this.headersDefault.find(
                    (header) => header.text === 'Czas',
                );
                timeHeader.value = 'distance';
                timeHeader.text = 'Dystans';
                const isDeclaredTimeHeader = this.headersDefault.find(
                    (header) => header.value === 'isDeclaredTime',
                );
                isDeclaredTimeHeader.value = 'isDeclaredDistance';
            }

            return insert(this.headersDefault, 6, checkpointsHeaders);
        },
        players() {
            return this.playersBy(this.classification);
        },
        items() {
            // filter and sort need for remote competitions.
            const filter = (player) => {
                let ageCriteria;
                let specialCriteria;

                if (this.selectedAgeCategory) {
                    ageCriteria = player.category.includes(
                        this.selectedAgeCategory.id,
                    );
                } else {
                    ageCriteria = true;
                }

                if (this.selectedSpecialCategory) {
                    specialCriteria = player.category.includes(
                        this.selectedSpecialCategory.id,
                    );
                } else {
                    specialCriteria = true;
                }

                return ageCriteria && specialCriteria;
            };
            const sort = (a, b, sortVar, isDesc = false) => {
                if (a[sortVar] === b[sortVar]) {
                    return 0;
                }
                if (a[sortVar] === null) {
                    return 1;
                }
                if (b[sortVar] === null) {
                    return -1;
                }
                if (!isDesc) {
                    return a[sortVar] < b[sortVar] ? -1 : 1;
                }
                return a[sortVar] < b[sortVar] ? 1 : -1;
            };
            let remotePlayers = [];
            let listPlayers = [];

            const findPlayer = (id) => this.players.find((player) => player.id === id);
            if (!this.competition.isRemote) {
                if (!this.positions) {
                    return [];
                }
                const players = [];
                for (const id of this.positions) {
                    players.push(findPlayer(id));
                }
                listPlayers = players.filter(filter);
            } else {
                remotePlayers = this.players.filter(filter);
                if (!this.remoteConfig.isTime) {
                    listPlayers = remotePlayers.sort((a, b) => sort(a, b, 'distance', true));
                } else {
                    listPlayers = remotePlayers.sort((a, b) => sort(a, b, 'time'));
                }
            }
            // @TODO refacoring.
            /*
            const formatTime = (time) => {
                if (
                    moment(time, '[P][Y][M]D[D][T]HH[H]mm[M]ss[S]SSS').format(
                        'D HH:mm:ss.SSS',
                    ) === 'Invalid date'
                ) {
                    return moment(
                        time,
                        '[P][Y][M]d[D][T]HH[H]mm[M]ss[S]SSS',
                    ).format('d HH:mm:ss.SSS');
                }
                return moment(
                    time,
                    '[P][Y][M]D[D][T]HH[H]mm[M]ss[S]SSS',
                ).format('D HH:mm:ss.SSS');
            };
            */
            const formatTime = (time) => {
            if (typeof time === 'string' && time.startsWith('P')) {
                // Parsowanie ISO 8601 duration: P0Y0M4DT12H32M12S123000F
                const match = time.match(/P(?:\d+Y)?(?:\d+M)?(?:(\d+)D)?T(\d+)H(\d+)M(\d+)S(\d{3})/);
                if (match) {
                const [, days = '0', hours, minutes, seconds, milliseconds] = match;
                
                // Formatowanie milisekund - zawsze 3 cyfry
                const formattedMilliseconds = String(milliseconds).padStart(3, '0');
                const formattedSeconds = String(seconds).padStart(2, '0');
                const formattedMinutes = String(minutes).padStart(2, '0');
                const formattedHours = String(hours).padStart(2, '0');

                // Format: D HH:mm:ss.SSS (np. 4 12:32:12.123)
                return `${String(days).padStart(1, '0')} ${formattedHours}:${formattedMinutes}:${formattedSeconds}.${formattedMilliseconds}`;
                }
            }

            // Fallback do moment.js jeśli to nie jest duration
            return moment(time, '[P][Y][M]D[D][T]HH[H]mm[M]ss[S]SSS').format('D HH:mm:ss.SSS');
            };
            // Create list.
            let i = 0;
            const list = [];
            for (const player of listPlayers) {
                const item = {
                    id: player.id,
                    name: player.isAnonymous
                        ? this.say.anonymous_participant
                        : `${player.lastname} ${player.firstname}`,
                    number: player.playerNumber,
                    country: player.country,
                    city: player.city,
                    clubname: player.clubname,
                    position: (i += 1),
                    isDeclaredTime: player.timeType === 'declared',
                    time: this.$options.filters.asStoper(player.time),
                    distance: player.distance,
                    isDeclaredDistance: player.distanceType === 'declared',
                };

                if (this.checkpointsHeaders.length) {
                    let j = 0;
                    for (const checkpoint of this.checkpointsHeaders) {
                        let time = '';

                        const checkpointSplitTime = moment.duration(
                            formatTime(this.splitTime[item.id]?.[j]?.time),
                        );
                        if (this.isSplitTime) {
                            time = this.$options.filters.asStoper(
                                checkpointSplitTime,
                            );
                        } else {
                            const checkpointLapTime = moment.duration(
                                formatTime(this.lapTime[item.id]?.[j]?.time),
                            );
                            time = this.$options.filters.asStoper(
                                checkpointLapTime,
                            );
                        }

                        if (checkpoint.number !== 1) {
                            // Removing hundredths of a second except meta.
                            [time] = time.split('.');
                        }

                        item[`checkpoint_${checkpoint.id}`] = time;
                        if (
                            [DNS_TIME, DNF_TIME, DSQ_TIME].includes(
                                checkpointSplitTime.valueOf(),
                            )
                        ) {
                            // Remove position and change lap time on DNS/DNF/DSQ instead time.
                            item.position = '';
                            item[
                                `checkpoint_${checkpoint.id}`
                            ] = this.$options.filters.asStoper(
                                checkpointSplitTime,
                            );
                        }
                        // eslint-disable-next-line no-plusplus
                        j++;
                    }
                }
                list.push(item);
            }

            return list;
        },
    },
    watch: {
        async isSplitTime() {
            if (!this.isSplitTime && !Object.values(this.lapTime).length) {
                this.ready = false;
                this.lapTime = await this.READ_CLASSIFICATION_RESULTS_LAP(
                    this.classificationId,
                );
                this.ready = true;
            }
        },
    },
    async created() {
        // const [{ open: positions }, splitTime] = await Promise.all([
        //     this.READ_CLASSIFICATION_RESULTS_POSITIONS_OPEN(
        //         this.classificationId,
        //     ),
        //     this.READ_CLASSIFICATION_RESULTS_SPLIT(this.classificationId),
        //     this.BOOTSTRAP_MINISITE(this.id),
        //     this.READ_CLASSIFICATION(this.classificationId),
        //     this.READ_CLASSIFICATION_PLAYERS(this.classificationId),
        //     this.READ_CLASSIFICATION_CHECKPOINTS(this.classificationId),
        // ]);

        const { open: positions } = await this.READ_CLASSIFICATION_RESULTS_POSITIONS_OPEN(
            this.classificationId,
        );
        const splitTime = await this.READ_CLASSIFICATION_RESULTS_SPLIT(this.classificationId);
        await this.BOOTSTRAP_MINISITE(this.id);
        await this.READ_CLASSIFICATION(this.classificationId);
        await this.READ_CLASSIFICATION_PLAYERS(this.classificationId);
        await this.READ_CLASSIFICATION_CHECKPOINTS(this.classificationId);

        if (this.competition.isRemote) {
            const remoteConfig = await this.READ_DECLARE_RESULT_CONFIG(this.id);
            this.remoteConfig = remoteConfig;
        }

        this.positions = positions;
        this.splitTime = splitTime;
        this.ready = true;
    },
    methods: {
        ...mapActions([
            READ_CLASSIFICATION,
            READ_CLASSIFICATION_PLAYERS,
            BOOTSTRAP_MINISITE,
            READ_CLASSIFICATION_CHECKPOINTS,
            READ_CLASSIFICATION_RESULTS_POSITIONS_OPEN,
            READ_CLASSIFICATION_RESULTS_LAP,
            READ_CLASSIFICATION_RESULTS_SPLIT,
            READ_DECLARE_RESULT_CONFIG,
        ]),
        declareResult() {
            const redirect = this.competition.status < 3
                ? 'user-competitions-future'
                : 'user-competitions-past';

            this.$router.push({
                name: redirect,
                alias: true,
            });
        },
    },
};
</script>
