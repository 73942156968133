<template>
  <div style="overflow: hidden"> 
    <main v-if="!ready">
      <div class="d-flex flex-column mat24">
          <h2 class="text-center">
              {{ say.results_are_loading }}
          </h2>
          <div class="mat16 lds-ellipsis lds-ellipsis--center">
              <div />
              <div />
              <div />
          </div>
      </div>
    </main>
    <div v-else>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="panel-competition-result-toolbar none-mobile">
              <div>
                <label for="">Międzyczasy</label>
                <div
                    v-if="Object.values(splitTime).length"
                >
                    <div class="list-header__switch row">
                        <div
                            class="list-header__switch-btn col-5"
                            :class="{
                                'list-header__switch-btn--right': isSplitTime,
                            }"
                            @click="isSplitTime = !isSplitTime"
                        >
                            {{ isSplitTime ? "SPLIT" : "LAP" }}
                        </div>
                    </div>
                </div>
              </div>
              <div class="classificationsWrap">
                <label for="">Klasyfikacja</label>
                <base-input-select
                  v-model="selectedClassification"
                  class="minimal"
                  :options="classificationsBy(competition)"
                  :clearable="false"
                  label="Klasyfikacja"
                  label-select="namePl"
                />
              </div>
              <div class="categoryWrap">
                <label for="" v-if="selectedClassification">Kategoria</label>
                <base-input-select
                  v-if="selectedClassification"
                  v-model="selectedCategory"
                  class="minimal"
                  :options="categoriesBy(selectedClassification)"
                  label="Kategoria"
                  label-select="namePl"
                />
              </div>
              <div class="searchWrap">
                <label for="">&nbsp;</label>
                <base-input-text-with-icon
                  v-model="search"
                  class="minimal"
                  label="Szukaj"
                  icon="fas fa-search"
                  badge="fas fa-times"
                  @icon-click="search = ''"
                />
              </div>
              <!-- <div>
                <label for="">Wgraj wyniki z pliku</label>
                <base-button btn2 class="minimal" icon="fas fa-cloud-upload-alt">Plik CSV</base-button>   
              </div> -->
              <div>
                <label for="">Wgraj wyniki z pliku</label>
                <base-button @click="
                        $router.push({
                            name: 'panel-competition-result-legacy',
                            params: competition,
                            alias: true,
                        })
                    " btn2 class="minimal" icon="fas fa-cloud-upload-alt">Plik CSV</base-button>   
              </div>
              <div>
                <label for="">Udostępnij wyniki</label>
                <base-button btn2 class="minimal" icon="fas fa-file-export" @click="openShareModal">Pobierz, wyślij...</base-button>   
              </div>             
              <!-- <div>
                <label for="">Pobierz wyniki</label>
                <div class="button-wrap">
                  <base-button @click="downloadResults('csv')" btn2 style="padding: 10px 15px;">CSV</base-button>   
                  <base-button @click="downloadResults('pdf')" btn2 style="padding: 10px 15px;">PDF</base-button>   
                </div> 
              </div> -->
              <!-- <div v-if="competition.status > 3">
                <label>Na żywo</label>
                <base-button 
                  @click="resultsLive"
                  btn2
                  style="padding: 10px 15px;"
                >
                  LIVE
                </base-button>   
              </div> -->
            </div>
            <div class="panel-competition-result-toolbar-mobile none-desktop">
              <div class="wrap">
                <label for="">Klasyfikacja</label>
                <base-input-select
                  v-model="selectedClassification"
                  class="minimal"
                  :options="classificationsBy(competition)"
                  :clearable="false"
                  label="Klasyfikacja"
                  label-select="namePl"
                />
              </div>
              <div class="buttons-wrap">
                <div class="btn" :class="{'is-active': buttonsTabs === 'search'}" id="search" @click="showCompetitionPanel('search')">
                  <span class="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.8536 20.1464L16.9994 16.2923C18.2445 14.882 19 13.0292 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19C13.0292 19 14.882 18.2445 16.2923 16.9994L20.1464 20.8536C20.3417 21.0488 20.6583 21.0488 20.8536 20.8536C21.0488 20.6583 21.0488 20.3417 20.8536 20.1464ZM18 11C18 14.866 14.866 18 11 18C7.13401 18 4 14.866 4 11C4 7.13401 7.13401 4 11 4C14.866 4 18 7.13401 18 11Z" fill="white"/>
                    </svg>
                    <div class="circle" :class="{'is-active': search !== ''}" ></div>
                  </span>
                  <span class="text">Szukaj</span>
                </div>  
                <div class="btn" :class="{'is-active': buttonsTabs === 'splitTime'}" id="splitTime" @click="showCompetitionPanel('splitTime')">
                  <span class="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM15.9069 14.7906C16.0674 14.5659 16.0153 14.2536 15.7906 14.0931L12.4355 11.6966L10.9824 6.36844C10.9097 6.10203 10.6349 5.94496 10.3684 6.01762C10.102 6.09028 9.94496 6.36515 10.0176 6.63156L11.5176 12.1316C11.5479 12.2427 11.6157 12.3399 11.7094 12.4069L15.2094 14.9069C15.4341 15.0674 15.7464 15.0153 15.9069 14.7906Z" fill="white"/>
                    </svg>
                  </span>
                  <span class="text">Międzyczasy</span>
                </div>  
                <div class="btn" :class="{'is-active': buttonsTabs === 'categories'}" id="categories" @click="showCompetitionPanel('categories')">
                  <span class="icon">
                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 22C6.97715 22 2.5 17.5228 2.5 12C2.5 6.47715 6.97715 2 12.5 2C18.0228 2 22.5 6.47715 22.5 12C22.5 17.5228 18.0228 22 12.5 22ZM12.5 21C17.4706 21 21.5 16.9706 21.5 12C21.5 7.02944 17.4706 3 12.5 3C7.52944 3 3.5 7.02944 3.5 12C3.5 16.9706 7.52944 21 12.5 21ZM6.5 9.5C6.5 9.77614 6.72386 10 7 10H18C18.2761 10 18.5 9.77614 18.5 9.5C18.5 9.22386 18.2761 9 18 9H7C6.72386 9 6.5 9.22386 6.5 9.5ZM9 13C8.72386 13 8.5 12.7761 8.5 12.5C8.5 12.2239 8.72386 12 9 12H16C16.2761 12 16.5 12.2239 16.5 12.5C16.5 12.7761 16.2761 13 16 13H9ZM10.5 15.5C10.5 15.7761 10.7239 16 11 16H14C14.2761 16 14.5 15.7761 14.5 15.5C14.5 15.2239 14.2761 15 14 15H11C10.7239 15 10.5 15.2239 10.5 15.5Z" fill="white"/>
                    </svg>
                    <div class="circle" :class="{'is-active': selectedCategory !== null}" ></div>
                  </span>
                  <span class="text">Kategoria</span>
                </div>  
                <div class="btn" :class="{'is-active': buttonsTabs === 'download'}" id="download" @click="openShareModal">
                  <span class="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.5 9.00003C19.2239 9.00003 19 8.77617 19 8.50003V7.00003H15.5C15.2239 7.00003 15 6.77617 15 6.50003V3.00003H6.5C5.67157 3.00003 5 3.6716 5 4.50003V19.5C5 20.3285 5.67157 21 6.5 21H17.5C18.3284 21 19 20.3285 19 19.5V17.5C19 17.2239 19.2239 17 19.5 17C19.7761 17 20 17.2239 20 17.5V19.5C20 20.8807 18.8807 22 17.5 22H6.5C5.11929 22 4 20.8807 4 19.5V4.50003C4 3.11931 5.11929 2.00003 6.5 2.00003H15.472C15.6048 1.99161 15.7429 2.03587 15.8536 2.14647L19.8536 6.14647C19.9642 6.25708 20.0084 6.39526 20 6.528V8.50003C20 8.77617 19.7761 9.00003 19.5 9.00003ZM18.2929 13H10.4861C10.21 13 9.98612 12.7762 9.98612 12.5C9.98612 12.2239 10.21 12 10.4861 12H18.2929L16.1464 9.85358C15.9512 9.65832 15.9512 9.34173 16.1464 9.14647C16.3417 8.95121 16.6583 8.95121 16.8536 9.14647L19.8536 12.1465C20.0488 12.3417 20.0488 12.6583 19.8536 12.8536L16.8536 15.8536C16.6583 16.0488 16.3417 16.0488 16.1464 15.8536C15.9512 15.6583 15.9512 15.3417 16.1464 15.1465L18.2929 13ZM16 6.00003V3.70713L18.2929 6.00003H16Z" fill="white"/>
                    </svg>
                  </span>
                  <span class="text">Pobierz</span>
                </div>  
              </div>
              <div class="buttons-content-wrap" :class="{'is-active': buttonsTabs !== null }">
                <div class="buttons-content" :class="{'is-active': buttonsTabs === 'search'}">
                  <base-input-text-with-icon
                    v-model="search"
                    class="minimal"
                    label="Szukaj"
                    icon="fas fa-search"
                    badge="fas fa-times"
                    @icon-click="search = ''"
                  />
                </div>
                <div class="buttons-content" :class="{'is-active': buttonsTabs === 'splitTime'}">
                  <div
                    v-if="Object.values(splitTime).length"
                    style="display: flex;align-items: center;"
                  >
                      <div class="list-header__switch row">
                          <div
                              class="list-header__switch-btn col-5"
                              :class="{
                                  'list-header__switch-btn--right': isSplitTime,
                              }"
                              @click="isSplitTime = !isSplitTime"
                          >
                              {{ isSplitTime ? "SPLIT" : "LAP" }}
                          </div>
                      </div>
                      <p v-if="isSplitTime">Wyświelnany jest czas łączny (narastający)</p>  
                      <p v-else>Wyświelnany jest czas odcinków lub okrążeń</p>  
                  </div>
                </div>
                <div class="buttons-content"  :class="{'is-active': buttonsTabs === 'categories'}">
                  <base-input-select
                    v-if="selectedClassification"
                    v-model="selectedCategory"
                    class="minimal"
                    :options="categoriesBy(selectedClassification)"
                    label="Kategoria"
                    label-select="namePl"
                  />
                </div>
                <!-- <div class="buttons-content" :class="{'is-active': buttonsTabs === 'download'}">
                  <div class="button-wrap">
                    <base-button @click="downloadResults('csv')" btn2 style="width:100%; padding: 10px 15px;">CSV</base-button>   
                    <base-button @click="downloadResults('pdf')" btn2 style="width:100%; padding: 10px 15px;">PDF</base-button>   
                  </div> 
                </div> -->
              </div>
            </div>
            <div v-if="competition.status === 4" class="panel-competition-players-on-the-route">
              <div>
                Liczba uczestników na trasie
              </div>
              <div>
                <div><b>{{ classificationPlayersOnTheRoute }}</b> w wybranej klasyfikacji</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-result">
        <base-table
            v-if="items.length"
            class="table-list"
            :headers="headers"
            :items="items"
            :search="search"
            sortColumnDefault="0"
            :sortReverseDefault="false"
        >
            <template v-slot:header="{ sort }">
                <tr class="table-list__row bg--dark">
                    <th
                        v-for="(header, column) in headers"
                        :key="column"
                        class="table-list__header"
                        :class="{
                            'table-list__header--second-cell':
                                column === 1,
                            'table-list__header--first-cell':
                                column === 0,
                            'd-none':
                                column === 1 &&
                                competition.isRemote &&
                                competition.id !== 4877 ||
                                header.value === 'meta_summary' && isSplitTime
                        }"
                        @click="sort(column)"
                    >
                        <template
                            v-if="
                                !isSplitTime &&
                                    column === headers.length - 3 &&
                                    !competition.isRemote
                            "
                        >
                            LAP 
                        </template>
                        <template v-else>
                            {{ header.text }}
                        </template>
                    </th>
                </tr>
            </template>

            <template v-slot:body="{ displayedItems }">
                <tr
                    v-for="item in displayedItems"
                    :key="item.id"
                    class="table-list__row"
                    @click="modal(item)"
                >
                    <td
                        v-for="(header, column) in headers"
                        :key="column"
                        class="table-list__data"
                        :class="{
                            'table-list__data--second-cell':
                                column === 1,
                            'table-list__data--first-cell':
                                column === 0,
                            'd-none':
                                column === 1 &&
                                competition.isRemote &&
                                competition.id !== 4877 ||
                                header.value === 'meta_summary' && isSplitTime,
                            'table-list__data--right-border':
                                column === 0 &&
                                competition.isRemote &&
                                competition.id !== 4877,
                            'table-list__data--padding':
                                header.value === 'type',
                            'align-right':
                                header.value === 'isDeclaredTime',
                        }"
                    >
                        <p v-if="header.value === 'meta_summary'">
                          {{ item.time }}
                        </p>
                        <p
                            v-else-if="
                                header.value === 'isDeclaredTime' &&
                                    item.isDeclaredTime
                            "
                            class="table-list__data--info-time"
                        >
                            {{ say.declared_time }}
                        </p>
                        <p
                            v-else-if="
                                header.value ===
                                    'isDeclaredDistance' &&
                                    item.isDeclaredDistance
                            "
                            class="table-list__data--info-time"
                        >
                            {{ say.declared_distance }}
                        </p>
                        <p
                            v-else-if="
                                (header.value ===
                                    'isDeclaredTime' &&
                                    !item.isDeclaredTime) ||
                                    (header.value ===
                                        'isDeclaredDistance' &&
                                        !item.isDeclaredDistance)
                            "
                        />
                        <template
                            v-else-if="
                                header.value === 'distance' &&
                                    item[header.value]
                            "
                        >
                            {{ `${item[header.value]} km` }}
                        </template>
                        <template 
                            v-else-if="column === 0"
                        >
                          <div v-if="selectedCategory">
                            <b>{{ selectedCategoryPosition(item.position) }}</b>
                          </div>
                          <div v-else>{{ item.position }}</div> 
                        </template>
                        <template v-else>
                            {{ item[header.value] }}
                        </template>
                    </td>
                </tr>
            </template>

            <template v-slot:no-results="{ search: term }">
                <p class="bg--gray pa16">
                    {{ say.no_search_results }}
                    <b>{{ term }}</b>
                </p>
            </template>
        </base-table>
      </div>
    </div>
    <modal ref="modalName" @modal-close="defaultModalData" maxHeight>
      <template v-slot:header>
        Korekta czasu
      </template>

      <template v-slot:body>
        <div class="selected-player-wrap">
          <div class="selected-player-details" :style="selectedPlayerMeasures && selectedPlayerMeasures.length > 0 ? '' : 'margin-bottom: 0'">
            <div>
              <label for="">Uczestnik</label>
              <b>{{ selectedPlayer.name }}</b>
            </div>
            <div>
              <label for="">Nr startowy</label>
              <b>{{ selectedPlayer.number }}</b>
            </div>
            <div v-if="selectedPlayer.position">
              <label for="">Miejsce</label>
              <b>{{ selectedPlayer.position }}</b>
            </div>
            <div v-if="selectedCategory">
              <label for="">Miejsce w kategorii {{ selectedCategory.namePl }}</label>
              <b>{{ selectedCategoryPosition(selectedPlayer.position) }}</b>
            </div>
            <div class="selectedPlayerStatusSelect">
              <base-input-select
                v-model="selectedPlayerStatus"
                class="minimal"
                :options="playerStatus"
                :clearable="false"
                label="Wybierz status"
                label-select="name"
                :error.sync="errors.status"
                @input="updatePlayerStatus"
              />
            </div> 
          </div>
          <div class="selected-player-gates" v-if="selectedPlayerMeasures && selectedPlayerMeasures.length > 0">
            <div class="selected-player-gates__header">
              <div>Bramka</div>
              <div>Czas urządzenia</div>
              <div>Czas poprawiony</div>
              <div>&nbsp;</div>
            </div>
            <div class="selected-player-gates__item" :class="{ 'is-waiting': isWaiting }" v-for="(measure, index) in selectedPlayerMeasures" :key="measure.id">
              <div><span class="none-desktop">Bramka</span>{{ gateName(measure.gate) }}&nbsp;({{ measure.gate }})</div>
              <div><span class="none-desktop">Czas urządz.</span><span :class="measure.invalidated ? 'line-through' : ''">{{ formatTime(measure.deviceIntervalTime) }}</span></div>
              <div>
                <span class="none-desktop">Czas poprawiony</span>
                <imask-input @focus="activeSaveButton(measure.gate + '_' + measure.id)" placeholder="HH:MM:SS.XXX" mask="00:00:00.000" :class="measure.invalidated ? 'not-active' : ''" v-model="measures[ measure.gate + '_' + measure.id ]" :name="`${measure.gate}_${measure.id}`" />
              </div>
              <div>
                <span class="none-desktop" style="opacity: 0;visibility: hidden;">Opcje</span>
                <div class="d-flex items-center">
                  <button class="btn btn-disabled" :ref="measure.gate + '_' + measure.id" @click="editPlayerTime(measure.gate, measure.id, $event)">Zapisz</button>
                  <div v-if="measure.invalidated" class="removeTime" @click="invalidatedTime(measure.id, false)">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M2.00001 3.34222C3.68698 1.25541 6.23675 0 9.00001 0C13.9706 0 18 4.02944 18 9C18 13.9706 13.9706 18 9.00001 18C4.23548 18 0.303942 14.2867 0.016742 9.55304C1.84961e-05 9.27741 0.209908 9.0404 0.485544 9.02368C0.761179 9.00696 0.998183 9.21685 1.01491 9.49248C1.27013 13.6991 4.76502 17 9.00001 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9.00001 1C6.53059 1 4.25396 2.12713 2.75426 4H5.50001C5.77615 4 6.00001 4.22386 6.00001 4.5C6.00001 4.77614 5.77615 5 5.50001 5H1.50001C1.22387 5 1.00001 4.77614 1.00001 4.5V0.5C1.00001 0.223858 1.22387 0 1.50001 0C1.77615 0 2.00001 0.223858 2.00001 0.5V3.34222Z" fill="#455A64"/>
                    </svg>
                  </div>
                  <div v-else class="removeTime" @click="invalidatedTime(measure.id, true)">
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M16 15.2929L20.1464 11.1464C20.3417 10.9512 20.6583 10.9512 20.8536 11.1464C21.0488 11.3417 21.0488 11.6583 20.8536 11.8536L16.7071 16L20.8536 20.1464C21.0488 20.3417 21.0488 20.6583 20.8536 20.8536C20.6583 21.0488 20.3417 21.0488 20.1464 20.8536L16 16.7071L11.8536 20.8536C11.6583 21.0488 11.3417 21.0488 11.1464 20.8536C10.9512 20.6583 10.9512 20.3417 11.1464 20.1464L15.2929 16L11.1464 11.8536C10.9512 11.6583 10.9512 11.3417 11.1464 11.1464C11.3417 10.9512 11.6583 10.9512 11.8536 11.1464L16 15.2929Z" fill="#455A64"/>
                    </svg>
                  </div>
                </div> 
              </div>
            </div>
          </div>
        </div>
        <div class="selected-player-addtime">
          <b class="title">Dodaj czas</b>
          <div class="selected-player-addtimeWrap">
            <div class="selected-player-addtime__header">
              <div>Bramka pomiarowa</div>
              <div>Czas</div>
              <div>&nbsp;</div>
            </div>
            <div class="selected-player-addtime__item" :class="{ 'is-waiting': isWaiting }">
              <div>
                <base-input-select
                  v-model="selectedPlayerGate"
                  class="minimal"
                  :options="gates"
                  :clearable="false"
                  label="Wybierz bramkę pomiarową"
                  label-select="name"
                  :error.sync="errors.gate"
                />
                <div v-if="selectedPlayerGateCheckpoints">
                  <label style="display: block;margin-bottom: 4px;">Punkty pomiarowe przypisane do bramki</label>
                  <b><span v-for="(checkpoint, index) in selectedPlayerGateCheckpoints" :key="checkpoint.id"><span v-if="index != 0">, </span><span>{{ checkpoint.name }}</span></span></b>
                </div>
              </div>
              <div>
                <imask-input placeholder="HH:MM:SS.XXX" mask="00:00:00.000" v-model="addGateTime" />
              </div>
              <div>
                <button class="btn" @click="addPlayerTime">Dodaj</button>
              </div>
            </div>
          </div>

        </div>
      </template>

      <template v-slot:footer>
        <div>&nbsp;</div>
        <button class="btn" @click="$refs.modalName.closeModal()">Zamknij</button>
      </template>
    </modal>
    <modal ref="shareModal">
      <template v-slot:header>Udostępnianie wyników</template>
      <template v-slot:body>
        <div class="shareUrl" >
          <p>Lista wyników dostępna jest pod adresem</p>
          <a :href="competitionPageLink" target="_blank">{{ competitionPageLink }}</a>  
        </div>
        <div class="shareCarouselWrap"  :class="{ 'is-waiting': isWaiting }">
          <div id="carousel" class="shareCarousel">
            <div class="shareCarouselItem" @click="isShareItemActive = 1" data-id="0" :class="{ 'active': isShareItemActive === 1 }">
              <h4>Pobierz wszyskie wyniki</h4>
              <p>Pełna lista wyników, zebrana w jednym dokumencie</p>
            </div>
            <div class="shareCarouselItem" @click="isShareItemActive = 2" data-id="1" :class="{ 'active': isShareItemActive === 2 }">
              <h4>Pobierz podium kategorii</h4>
              <p>Lista medalowych miejsc w kategoriach</p>
            </div>
            <div class="shareCarouselItem" @click="isShareItemActive = 3" data-id="2" :class="{ 'active': isShareItemActive === 3 }">
              <h4>Wyślij do uczestników</h4>
              <p>Lista wyników, uwzględniająca ustawione filtry</p>
            </div>
            <div v-if="competition.status > 3 && resultsLiveLink.length > 0" data-id="3" class="shareCarouselItem" @click="isShareItemActive = 4" :class="{ 'active': isShareItemActive === 4 }">
              <h4>Wyniki live</h4>
              <p>Lista wyników na żywo</p>
            </div>
          </div>
        </div>
        <div class="shareContent"  :class="{ 'is-waiting': isWaiting }">
          <div class="shareContentItem" :class="{ 'active': isShareItemActive === 1 }">
            <div class="row">
              <div class="col-12 col-md-4">
                <h3>Format pliku</h3>
                <div class="radio-field">
                  <input v-model="shareFile" id="pdf" type="radio" class="d-none" value="pdf"><label for="pdf" class="radio-field__label">PDF</label>
                  <input v-model="shareFile" id="csv" type="radio" class="d-none" value="csv"><label for="csv" class="radio-field__label">CSV</label>
                </div>
              </div>
              <div class="col-12 col-md-5">
                <h3>Wyświetlanie międzyczasów</h3>
                <label class="label">Międzyczasy</label>
                <div class="row">
                  <div class="col-5">
                    <div class="list-header__switch row">
                      <div
                        class="list-header__switch-btn col-5"
                        :class="{
                            'list-header__switch-btn--right': isSplitTimeResults,
                        }"
                        @click="isSplitTimeResults = !isSplitTimeResults"
                      >
                          {{ isSplitTimeResults ? "SPLIT" : "LAP" }}
                      </div>
                    </div>                
                  </div>
                  <div class="col-6">{{ isSplitTimeResults ? 'Wyświetlnany jest czas łączny (narastający)' : 'Wyświetlnany jest czas odcinków lub okrążeń' }}</div>
                </div>
              </div>
              <div class="col-md-3">&nbsp;</div>
            </div>
          </div>
          <div class="shareContentItem" :class="{ 'active': isShareItemActive === 2 }">
            <div class="row">
              <div class="col-12 col-md-5">
                <h3>Liczba miejsc dla każdej kategorii</h3>  
                <div class="row">
                  <div class="col-12 col-md-5">
                    <label for="" class="label">Kategoria open</label>
                    <base-input-text v-model="place_open" />
                  </div>
                  <div class="col-12 col-md-5">
                    <label for="" class="label">Kategoria wiekowe</label>
                    <base-input-text v-model="place_category" />                
                  </div>
                </div>
              </div>
            </div>
            <div class="row" style="margin-top: 24px">
              <div class="col-12 col-md-6">
                <h3>Czy zawodnicy z klasyfikacji open mają pojawić się w kategoriach wiekowych?</h3>
                <div class="radio-field">
                  <input v-model="repeat" :value="1" id="yes_1" type="radio" class="d-none" value="yes_1"><label for="yes_1" class="radio-field__label">Tak</label>
                  <input v-model="repeat" :value="0" id="no_1" type="radio" class="d-none" value="no_1"><label for="no_1" class="radio-field__label">Nie</label>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <h3>Czy na liście ma pojawić się podium z każdej klasyfikacji?</h3>
                <div class="radio-field">
                  <input v-model="all_classification" :value="1" id="all_classification_yes_1" type="radio" class="d-none" value="yeall_classification_yes_1s_1"><label for="all_classification_yes_1" class="radio-field__label">Tak</label>
                  <input v-model="all_classification" :value="0" id="all_classification_no_1" type="radio" class="d-none" value="all_classification_no_1"><label for="all_classification_no_1" class="radio-field__label">Nie</label>
                </div>
              </div>
            </div>
          </div>
          <div class="shareContentItem" :class="{ 'active': isShareItemActive === 3 }">
            <div class="row">
              <div class="col-12 col-md-5">
                <h3 style="margin-bottom: 24px;">Wybierz formę wysyłki</h3>
                <div class="radio-field">
                  <input :disabled="competition.status < 5" v-model="shareResultsType" id="email" type="radio" class="d-none" value="email"><label for="email" class="radio-field__label">Email<small v-if="competition.status < 5">Wysyłka możliwa tylko, gdy zawody są w statusie: <b>oficjalnym, nieoficjalnym</b></small></label>
                  <input :disabled="competition.status < 5" v-model="shareResultsType" id="sms" type="radio" class="d-none" value="sms"><label for="sms" class="radio-field__label">SMS <small v-if="competition.status < 5">Wysyłka możliwa tylko, gdy zawody są w statusie: <b>oficjalnym, nieoficjalnym</b></small></label>
                </div>
              </div>
              <div v-if="Object.keys(smsQuantity).length > 0" class="col-12 col-md-5">
                <h3 style="margin-bottom: 8px;">Sms zostaną wysłane do <b>{{ smsQuantity.smsesCount}}</b> uczestników</h3>
                <p>Opłata wynosi: <b>{{ smsQuantity.price}} zł</b></p>
                <p>Wysyłana treść: </p> 
                <p>"<b>{{ smsQuantity.information}}</b>"</p>
              </div>
              <!-- <div class="col-12 col-md-5">
                <h3 style="margin-bottom: 8px;">Wyniki zostaną wysłane do uczestników</h3>
                <p>na adresy email podane podczas rejestracji</p>
                <p style="margin-top: 24px">Ostatnia wysyłka: <b>wyniki nie zostały wcześniej wysłane</b></p>
              </div> -->
            </div>
          </div>
          <div v-if="competition.status > 3 && resultsLiveLink.length > 0" class="shareContentItem" :class="{ 'active': isShareItemActive === 4 }">
            <div class="row">
              <div class="col-12">
                <h3 style="margin-bottom: 8px;">Wyniki live</h3>
                <ul>
                  <li v-for="(live, index) in resultsLiveLink" :key="index" style="margin-bottom: 16px">
                    <b>{{ live.name }} ({{ live.short }})</b>
                    <a :href="live.url" target="_blank" rel="noopener noreferrer" style="display: block">{{ live.url }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <button class="btn" @click="$refs.shareModal.closeModal()"  :class="{ 'is-waiting': isWaiting }">Anuluj</button>
        <button v-if="isShareItemActive === 3" class="btn btn-primary" @click="downloadResultsWrap"  :class="{ 'is-waiting': isWaiting }">Wyślij</button>
        <button v-else-if="isShareItemActive !== 4" class="btn btn-primary" @click="downloadResultsWrap"  :class="{ 'is-waiting': isWaiting }">Pobierz</button>
      </template>
    </modal>
    <div class="loader" v-if="loading">
      <div class="loader__backdrop"/>
      <div class="lds-ellipsis lds-ellipsis--center">
        <div />
        <div />
        <div />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
    READ_COMPETITION_CLASSIFICATIONS,
    READ_COMPETITION_GATES,
    READ_CLASSIFICATION,
    READ_CLASSIFICATION_PLAYERS,
    READ_CLASSIFICATION_CHECKPOINTS,
    READ_CLASSIFICATION_RESULTS_POSITIONS_OPEN,
    READ_CLASSIFICATION_RESULTS_SPLIT,
    READ_CLASSIFICATION_RESULTS_LAP,
    READ_DECLARE_RESULT_CONFIG,
    READ_PLAYERS_MEASURES,
    CREATE_PLAYER_MEASURES,
    UPDATE_PLAYER_MEASURES,
    INVALIDATED_PLAYER_MEASURES,
    READ_RESULTS_EDIT_STATUS,
    UPDATE_PLAYERS,
} from '@/store/actions.type';
import actions from '@/mixins/actions';
import { insert } from '@/utils/array';
import moment from 'moment';
import { DNS_TIME, DNF_TIME, DSQ_TIME } from '@/config';
import Modal from '@/components/Modal';
import { sortBy } from 'lodash';
import api from '@/api';
import { downloadCsv } from '@/utils/files';
import {IMaskComponent} from 'vue-imask';

export default {
  name: 'PanelCompetitionResult',
  components: {
    Modal,
    'imask-input': IMaskComponent
  },
  mixins: [actions],
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      ready: false,
      loading: false,
      search: '',
      selectedClassification: null,
      selectedCategory: null,
      headersDefault: [
          { text: '', value: 'position' },
          { text: 'Nr', value: 'number' },
          { text: 'Nazwisko i imię', value: 'name' },
          { text: 'Płeć', value: 'gender' },
          { text: 'Kraj', value: 'country' },
          { text: 'Miejscowość', value: 'city' },
          { text: 'Klub', value: 'clubname' },
          { text: '', value: 'isDeclaredTime' },
          { text: 'Czas', value: 'time' },
      ],
      positions: [],
      splitTime: {},
      lapTime: {},
      isSplitTime: true,
      remoteConfig: {},
      selectedPlayer: null,
      selectedPlayerGate: null,
      selectedPlayerGateCheckpoints: null,
      selectedPlayerMeasures: null,
      selectedPlayerStatus: null,
      addGateTime: null,
      measures: {},
      isWaiting: false,
      errors: {},
      buttonsTabs: null,
      isShareItemActive: 1,
      shareFile: 'pdf',
      shareResultsType: 'email',
      smsQuantity: {},
      isSplitTimeResults: true,
      place_open: 3,
      place_category: 3,
      all_classification: 0,
      repeat: 0,
      playerStatus: [
        {"value":0,"name":"Udział"},
        {"value":1,"name":"Nie wystartował"},
        {"value":2,"name":"Nie Ukończył"},
        {"value":3,"name":"Dyskwalifikacja"}
      ]
    };
  },
  computed: {
    ...mapGetters([
      'say',
      'competitions',
      'competitionsSettings',
      'classifications',
      'classificationsBy',
      'categoriesBy',
      'checkpointsBy',
      'playersBy',
      'results',
      'gatesBy',
    ]),
    gates() {
      return this.gatesBy(this.checkpoints);
    },
    classification() {
      return this.classifications[this.classificationId];
    },
    competitionSetting() {
      return this.competitionsSettings[this.id];
    },    
    competition() {
      return this.competitions[this.id];
    },
    classificationId() {
      return this.selectedClassification ? this.selectedClassification.id : this.classificationsBy(this.competition)[0].id;
    },
    categories() {
      return this.categoriesBy(this.classification);
    },    
    ageCategories() {
      return this.categories.filter((category) => !category.special);
    },
    specialCategories() {
      return this.categories.filter((category) => category.special);
    },
    checkpoints() {
        const numberAsc = (a, b) => a.number - b.number;

        return this.checkpointsBy(this.classification).sort(numberAsc);
    },
    checkpointsHeaders() {
        if (!this.competition.isRemote) {
            const notStart = (checkpoint) => checkpoint.number > 0;

            return this.checkpoints.filter(notStart);
        }
        return [];
    },
    headers() {
        const checkpointsHeaders = this.checkpointsHeaders.map(
            (checkpoint) => ({
                text: checkpoint.name,
                value: `checkpoint_${checkpoint.id}`,
            }),
        );
        if (!this.competition.isRemote) {
            const headersDefaultWithoutTimeFiled = this.headersDefault.filter(
                (header) => header.text !== 'Czas',
            );
            const meta = [{text: 'Meta', value: 'meta_summary'}]
            const checkpointsHeadersWithLap = insert(checkpointsHeaders, checkpointsHeaders.length, meta)

            return insert(
                headersDefaultWithoutTimeFiled,
                7,
                checkpointsHeadersWithLap,
            );
        }
        if (!this.remoteConfig.isTime) {
            const timeHeader = this.headersDefault.find(
                (header) => header.text === 'Czas',
            );
            timeHeader.value = 'distance';
            timeHeader.text = 'Dystans';
            const isDeclaredTimeHeader = this.headersDefault.find(
                (header) => header.value === 'isDeclaredTime',
            );
            isDeclaredTimeHeader.value = 'isDeclaredDistance';
        }

        return insert(this.headersDefault, 7, checkpointsHeaders);
    },
    players() {
      return this.playersBy(this.classification);
    },
    items() {
      const filtered = this.players.filter((player) => {
        if (this.selectedCategory) {
            return player.category.includes(this.selectedCategory.id);
        }

        if (this.selectedClassification) {
            return (
                player.classification === this.selectedClassification.id
            );
        }

        return true;
      });
      /*
      const formatTime = (time) => {
          if (
              moment(time, '[P][Y][M]D[D][T]HH[H]mm[M]ss[S]SSS').format(
                  'D HH:mm:ss.SSS',
              ) === 'Invalid date'
          ) {
              return moment(
                  time,
                  '[P][Y][M]d[D][T]HH[H]mm[M]ss[S]SSS',
              ).format('d HH:mm:ss.SSS');
          }
          return moment(
              time,
              '[P][Y][M]D[D][T]HH[H]mm[M]ss[S]SSS',
          ).format('D HH:mm:ss.SSS');
      };
      */
      const formatTime = (time) => {
        if (typeof time === 'string' && time.startsWith('P')) {
          // Parsowanie ISO 8601 duration: P0Y0M4DT12H32M12S123000F
          const match = time.match(/P(?:\d+Y)?(?:\d+M)?(?:(\d+)D)?T(\d+)H(\d+)M(\d+)S(\d{3})/);
          if (match) {
            const [, days = '0', hours, minutes, seconds, milliseconds] = match;
            
            // Formatowanie milisekund - zawsze 3 cyfry
            const formattedMilliseconds = String(milliseconds).padStart(3, '0');
            const formattedSeconds = String(seconds).padStart(2, '0');
            const formattedMinutes = String(minutes).padStart(2, '0');
            const formattedHours = String(hours).padStart(2, '0');

            // Format: D HH:mm:ss.SSS (np. 4 12:32:12.123)
            return `${String(days).padStart(1, '0')} ${formattedHours}:${formattedMinutes}:${formattedSeconds}.${formattedMilliseconds}`;
          }
        }

        // Fallback do moment.js jeśli to nie jest duration
        return moment(time, '[P][Y][M]D[D][T]HH[H]mm[M]ss[S]SSS').format('D HH:mm:ss.SSS');
      };
      const findPlayerPosition = (id) => this.positions && this.positions.findIndex((position) => position === id);

        let i = 0;
        const list = [];

        for (const player of filtered) {
          i += 1;
          const playerPosition = findPlayerPosition(player.id);

          const item = {
              id: player.id,
              position: playerPosition !== undefined && playerPosition !== -1 ? playerPosition + 1 : null,
              name: `${player.lastname} ${player.firstname}`,
              gender: player.gender,
              number: player.playerNumber,
              country: player.country,
              city: player.city,
              clubname: player.clubname,
              classificationId: player.classification,
              isDeclaredTime: player.timeType === 'declared',
              time: this.$options.filters.asStoper(player.time),
              distance: player.distance,
              isDeclaredDistance: player.distanceType === 'declared',
              status: player.status,
          }
          if (this.checkpointsHeaders.length) {
            let j = 0;
            for (const checkpoint of this.checkpointsHeaders) {
                let time = '';

                const checkpointSplitTime = moment.duration(
                    formatTime(this.splitTime[item.id]?.[j]?.time),
                );
                if (this.isSplitTime) {
                    time = this.$options.filters.asStoper(
                        checkpointSplitTime,
                    );
                } else {
                    const checkpointLapTime = moment.duration(
                        formatTime(this.lapTime[item.id]?.[j]?.time),
                    );
                    time = this.$options.filters.asStoper(
                        checkpointLapTime,
                    );
                }

                if (checkpoint.number !== 1) {
                    // Removing hundredths of a second except meta.
                    [time] = time.split('.');
                }

                item[`checkpoint_${checkpoint.id}`] = time;
                if (
                    [DNS_TIME, DNF_TIME, DSQ_TIME].includes(
                        checkpointSplitTime.valueOf(),
                    )
                ) {
                    // Remove position and change lap time on DNS/DNF/DSQ instead time.
                    item.position = null;
                    item[
                        `checkpoint_${checkpoint.id}`
                    ] = this.$options.filters.asStoper(
                        checkpointSplitTime,
                    );
                }
                // eslint-disable-next-line no-plusplus
                j++;
            }
          }
          if(player.playerNumber !== 0) list.push(item);
        }

        return list;
    },
    categoryItems() {
      return sortBy(this.items, (item) => item[this.headers[0].value]);
    }, 
    competitionPageLink() {
      const host = window.location.origin;
      const { href } = this.$router.resolve({
          name: 'minisite-results-pick-classification',
          params: this.competition,
          alias: true,
      });

      return `${host}${href}`;
    }, 
    resultsLiveLink() {
      const classifications = this.classificationsBy(this.competition);
      const array = []
      for (const classification of classifications) {
        const host = window.location.origin;
        const params = {
          name: this.competition.name,
          id: this.competition.id,
          classificationId: classification.id,
        }
        const { href } = this.$router.resolve({
          name: 'panel-competition-results-live',
          params,
          alias: true,
        });

        const item = {
          name: classification.namePl,
          short: classification.short,
          url: `${host}${href}`,
        }
        array.push(item)
      }

      return array;
    },
    classificationPlayersOnTheRoute() {
      return this.items.filter((player) => player.position === null && player.status === 0).length;
    },
  },
  watch: {
    async isSplitTime() {
      if (!this.isSplitTime && !Object.values(this.lapTime).length) {
          this.ready = false;
          this.lapTime = await this.READ_CLASSIFICATION_RESULTS_LAP(
              this.classificationId,
          );
          this.ready = true;
      }
    },
    selectedClassification() {
      this.bootstrap();
      this.selectedCategory = null;
    }, 
    selectedPlayerGate() {
      if(this.selectedPlayerGate) {
        this.selectedPlayerCheckpoints();
      }
    },
    shareResultsType() {
      if(this.shareResultsType === 'sms') {
        this.checkSmsQuantity()
      } else {
        this.smsQuantity = {}
      }
    }
  },
  async created() {
    await this.READ_COMPETITION_CLASSIFICATIONS(this.competition.id);
    this.selectedClassification = this.classifications[this.classificationId];
    // this.bootstrap();
  },
  methods: {
    ...mapActions([
      READ_COMPETITION_CLASSIFICATIONS,
      READ_COMPETITION_GATES,
      READ_CLASSIFICATION,
      READ_CLASSIFICATION_PLAYERS,
      READ_CLASSIFICATION_CHECKPOINTS,
      READ_CLASSIFICATION_RESULTS_POSITIONS_OPEN,
      READ_CLASSIFICATION_RESULTS_LAP,
      READ_CLASSIFICATION_RESULTS_SPLIT,
      READ_DECLARE_RESULT_CONFIG,
      READ_PLAYERS_MEASURES,
      CREATE_PLAYER_MEASURES,
      UPDATE_PLAYER_MEASURES,
      INVALIDATED_PLAYER_MEASURES,
      READ_RESULTS_EDIT_STATUS,
      UPDATE_PLAYERS,
    ]),
    async bootstrap() {
      this.ready = false
        const [{ open: positions }, splitTime] = await Promise.all([
            this.READ_CLASSIFICATION_RESULTS_POSITIONS_OPEN(
                this.classificationId,
            ),
            this.READ_CLASSIFICATION_RESULTS_SPLIT(this.classificationId),
            this.READ_CLASSIFICATION(this.classificationId),
            this.READ_CLASSIFICATION_PLAYERS(this.classificationId),
            this.READ_CLASSIFICATION_CHECKPOINTS(this.classificationId),
            this.READ_COMPETITION_GATES(this.competition.id),
            this.READ_RESULTS_EDIT_STATUS(this.competition.id),
        ]);
        if (this.competition.isRemote) {
            const remoteConfig = await this.READ_DECLARE_RESULT_CONFIG(this.id);
            this.remoteConfig = remoteConfig;
        }

        this.positions = positions;
        this.splitTime = splitTime;
        this.ready = true;
    },
    modal(item) {
      this.loading = true
      this.selectedPlayer = item
      this.READ_PLAYERS_MEASURES(item.id).then((data) => {
        this.selectedPlayerMeasures = data
        for (const measure of this.selectedPlayerMeasures) {
          this.measures[ measure.gate + '_' + measure.id ] = this.formatTime(measure.manualIntervalTime)
        }
        if(this.selectedPlayer.status !== null) {
          this.selectedPlayerStatus = this.playerStatus[this.selectedPlayer.status]
        }
        this.loading = false
        this.$refs.modalName.openModal()
      })
    },
    updatePlayerStatus() {
      if(this.selectedPlayer !== null) {
        const player = {
          id: this.selectedPlayer.id,
          status: this.selectedPlayerStatus.value
        }
        this.UPDATE_PLAYERS(player)
          .then(() => {
            this.bootstrap().then(() => {
              const item = this.items.find(item => item.id === this.selectedPlayer.id)
              this.selectedPlayer = item
            })
          })
      }
    },    
    selectedCategoryPosition(position) {
      let positionIndex;
      if(position !== null) {
        positionIndex = (this.categoryItems.findIndex(item => item.position === position) + 1)
      } else {
        positionIndex = '-'
      }
      return positionIndex
    },
    defaultModalData() {
      this.selectedPlayer = null
      this.selectedPlayerGate = null
      this.selectedPlayerStatus = null
      this.selectedPlayerMeasures = null
      this.selectedPlayerGateCheckpoints = null
    },
    /*
    formatTime(time) {
      if (
        moment(time, '[P][Y][M]D[D][T]HH[H]mm[M]ss[S]SSS').format(
            'HH:mm:ss.SSS',
        ) === 'Invalid date'
      ) {
        return moment(
            time,
            '[P][Y][M]d[D][T]HH[H]mm[M]ss[S]SSS',
        ).format('HH:mm:ss.SSS');
      }
        return moment(
            time,
            '[P][Y][M]D[D][T]HH[H]mm[M]ss[S]SSS',
        ).format('HH:mm:ss.SSS');
    },
    */
    formatTime(time) {
      // Sprawdź, czy to format duration (zaczyna się od "P")
      if (typeof time === 'string' && time.startsWith('P')) {
        const match = time.match(/P(?:\d+Y)?(?:\d+M)?(?:(\d+)D)?T(\d+)H(\d+)M(\d+)S(\d{3})/);
        if (match) {
          const days = match[1] || '0';
          const hours = match[2];
          const minutes = match[3];
          const seconds = match[4];
          const milliseconds = match[5];

          // Formatowanie wyniku
          return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}.${milliseconds}`;
        }
      }

      // fallback – może to nie jest duration tylko pełna data?
      return moment(time, '[P][Y][M]D[D][T]HH[H]mm[M]ss[S]SSS').format('HH:mm:ss.SSS');
    },
    gateName(id) {
      const gate = this.gates.find(gate => gate.id === id)
      return gate.name
    },
    selectedPlayerCheckpoints() {
      const checkpoints = this.checkpoints.filter(checkpoint => checkpoint.gates.includes(this.selectedPlayerGate.id))

      this.selectedPlayerGateCheckpoints = checkpoints
    },
    editPlayerTime(gate, id, $event) {
      this.isWaiting = true
      const time = moment.duration(this.measures[`${gate}_${id}`]).toISOString();
      const item = {
        id: this.selectedPlayer.id,
        measure: {
          "measure": id,
          "result": time,
        }
      }
      this.UPDATE_PLAYER_MEASURES(item).then((data) => {
        const index = this.selectedPlayerMeasures.findIndex(item => item.id === id)
        this.selectedPlayerMeasures[index] = data
        // this.measures[`${gate}_${id}`] = null
        this.isWaiting = false
        $event.target.classList.add('btn-success')
        $event.target.innerText = "Zapisano"
        setTimeout(function() {
          $event.target.classList.remove('btn-success')
          $event.target.innerText = "Zapisz"
        }, 2000)
      })
      .then(() => {
        this.bootstrap().then(() => {
          const item = this.items.find(item => item.id === this.selectedPlayer.id)
          this.selectedPlayer = item
        })
      })
      .catch(error => {
        this.isWaiting = false
        console.error(error);
      });
    },
    addPlayerTime() {

        this.isWaiting = true
        const time = moment.duration(this.addGateTime);
        const item = {
          id: this.selectedPlayer.id,
          measure: {
            "gate": this.selectedPlayerGate ? this.selectedPlayerGate.id : null,
            "result": time,
          }
        }
  
        this.CREATE_PLAYER_MEASURES(item).then((data) => {
          this.selectedPlayerMeasures.push(data)
          this.isWaiting = false
          this.addGateTime = null
        })
        .then(() => {
          this.bootstrap().then(() => {
            const item = this.items.find(item => item.id === this.selectedPlayer.id)
            this.selectedPlayer = item
          })
        })
        .catch(error => {
          this.isWaiting = false
          this.errors = error;
        });

    },
    invalidatedTime(id, invalidated) {
      this.isWaiting = true
      const item = {
        id,
        invalidated,
      }
      this.INVALIDATED_PLAYER_MEASURES(item).then(() => {
        this.selectedPlayerMeasures.find(item => item.id === id).invalidated = invalidated
        this.bootstrap().then(() => {
          const item = this.items.find(item => item.id === this.selectedPlayer.id)
          this.selectedPlayer = item
        })
        this.isWaiting = false
      })
      .catch(error => {
        this.isWaiting = false
        console.error(error);
      });
    },
    async downloadResults(file) {
      this.isWaiting = true
      const data = await api.get(`/classifications/${this.classificationId}/results`, {
        responseType: 'blob',
        headers: {
          'Content-Type': `${file === 'csv' ? 'text/csv' : 'application/pdf'}`,
          Accept: `${file === 'csv' ? 'text' : 'application'}/${file}+${this.isSplitTimeResults ? 'split' : 'lap'}`,
        },
        data: {},
      });
      
      if(file === 'csv') {
        downloadCsv(`klasyfikacja-${this.classificationId}-${this.classification.short}-${this.classification.namePl.replace(/\s/g, "_")}-${this.isSplitTimeResults ? 'split' : 'lap'}`, data)
      }

      if(file === 'pdf') {
        var blob = new Blob([data], {type: "application/pdf"});
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `klasyfikacja-${this.classificationId}-${this.classification.short}-${this.classification.namePl.replace(/\s/g, "_")}-${this.isSplitTimeResults ? 'split' : 'lap'}.pdf`;
        link.click();
      }
      this.isWaiting = false
    },
    async downloadPodiumResults() {
      this.isWaiting = true
      const data = await api.get(`/classifications/${this.classificationId}/results?repeat=${this.repeat}&place_open=${this.place_open}&place_category=${this.place_category}&all_classification=${this.all_classification}`, {
        responseType: 'blob',
        headers: {
          'Content-Type': `application/pdf`,
          Accept: `application/pdf+podium`,
        },
        data: {},
      });
      var blob = new Blob([data], {type: "application/pdf"});
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `klasyfikacja-${this.classificationId}-${this.classification.short}-${this.classification.namePl.replace(/\s/g, "_")}-podium-open_${this.place_open}-category_${this.place_category}-repeat_${this.repeat}-all_classification_${this.all_classification}.pdf`;
      link.click();
      this.isWaiting = false
    },
    async sendResultsEmail() {
      this.isWaiting = true
      await api.post(`/classifications/${this.classificationId}/results/email`)
      this.isWaiting = false
    },
    async checkSmsQuantity() {
      this.isWaiting = true
      const result = await api.post(`/sms/quantity`, { "competition": this.competition.id, "playersClassification": this.classificationId, "type": "result" })
      this.smsQuantity = result
      this.isWaiting = false
    },
    async sendResultsSms() {
      this.isWaiting = true
      const result = await api.post(`/sms/send`, {"competition": this.competition.id, "user": this.smsQuantity.user, "information": this.smsQuantity.information, "playersClassification": this.classificationId, "playersPay": this.smsQuantity.playersPay, "type": "result" })
      window.location = result.link
    },    
    showCompetitionPanel(item) {
      if(this.buttonsTabs === item) {
        this.buttonsTabs = null
        return
      }
      this.buttonsTabs = item
    },
    activeSaveButton(id) {
      Array.from(document.querySelectorAll('.selected-player-gates__item .btn')).forEach((element,index) => { 
        element.classList.add('btn-disabled')
      });
      this.$refs[id][0].classList.remove('btn-disabled')
    },
    resultsLive() {
      const params = {
        name: this.competition.name,
        id: this.competition.id,
        classificationId: this.selectedClassification.id,
      }
      this.$router.push({
        name: 'panel-competition-results-live',
        params,
        alias: true,
      })
    },
    openShareModal() {
      this.$refs.shareModal.openModal()
      setTimeout(() => {
        this.addCarouselListener()
      }, 500)
    },
    downloadResultsWrap() {
      if(this.isShareItemActive === 1) {
        this.downloadResults(this.shareFile)
      } else if(this.isShareItemActive === 2) {
        this.downloadPodiumResults()
      } else if(this.isShareItemActive === 3 && this.shareResultsType === 'email') {
        this.sendResultsEmail()
      } else if(this.isShareItemActive === 3 && this.shareResultsType === 'sms') {
        this.sendResultsSms()
      }
    },
    elementInViewport(item) {
      const bounding = item.getBoundingClientRect();
      return (
          bounding.left >= 0
          && bounding.right
              <= (window.innerWidth || document.documentElement.clientWidth)
      );
    }, 
    activeClassWatcher() {
      const head = document.getElementsByClassName(
        'shareCarouselItem',
      );

      for (const elem in head) {
        const id = head.item(elem).dataset.id
        if (this.elementInViewport(head.item(elem))) {
          head.item(elem).className = 'shareCarouselItem active';
          document.querySelectorAll('.shareContentItem')[id].className = 'shareContentItem active';
          this.isShareItemActive = parseInt(id)+1
        } else {
          head.item(elem).className = 'shareCarouselItem';
          document.querySelectorAll('.shareContentItem')[id].className = 'shareContentItem';
        }
      }
    }, 
    addCarouselListener() {
      const carousel = document.getElementById('carousel');
      carousel.addEventListener('scroll', this.activeClassWatcher);
    },          
  },
}
</script>

<style scoped>
.panel-competition-result-toolbar {
  background: #455A64;
  border-radius: 4px;
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 24px 32px 32px;
  display: flex;
  justify-content: space-between;
}
.panel-competition-result-toolbar label {
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 20px;
  min-height: 20px;
}
.panel-competition-result-toolbar .list-header__switch,
.panel-competition-result-toolbar-mobile .list-header__switch,
.panel-competition-result-toolbar .list-header__switch-btn,
.panel-competition-result-toolbar-mobile .list-header__switch-btn,
.shareContent .list-header__switch,
.shareContent .list-header__switch-btn {
  border-radius: 4px;
}

.panel-competition-result-toolbar-mobile .list-header__switch {
  margin-top: 0;
  flex-shrink: 0;
  margin-right: 16px;
}

.panel-competition-result-toolbar .input-field.minimal {
  min-height: 40px;
}
.panel-competition-result-toolbar .btn.minimal {
  padding: 10px 15px;
}
.panel-competition-result-toolbar .categoryWrap, 
.panel-competition-result-toolbar .searchWrap {
  width: 157px;
}
.panel-competition-result-toolbar .classificationsWrap {
  width: 217px;
}
tbody .table-list__row:nth-of-type(even) {
  background-color: #fff;
  box-shadow: 0 1px 0 0 #e0e0e0, inset 0 1px 0 0 #e0e0e0;
}
.container-result {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
}
.table-list {
  color: #000;
}
.table-list__row {
  cursor: pointer;
}

.modal .btn {
  height: 40px;
  min-width: 167px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #546E7A;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: 0.3s all;
}

.modal .btn-disabled {
  background: #ECEFF1;
  border: 1px solid #CFD8DC;
  box-shadow: none;
  color: #455A64;
  pointer-events: none;
}

.modal .btn-success {
  background: #50AE55;
  color: #fff;
}

.modal .btn-primary {
  min-width: 268px;
  background: linear-gradient(270deg, #FF3D00 0%, #FF1744 99.2%);
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
  text-transform: uppercase;
}

.selected-player-details {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 32px;
}

.selected-player-details > div:not(:last-child) {
  margin-right: 32px;
}
.selected-player-details > div:first-child {
  min-width: 268px;
}

.selected-player-details label {
  display: block;
  font-size: 14px;
  line-height: 20px;
}

.selected-player-details b {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
.selected-player-gates__header {
  display: flex;
  padding: 0 16px;
  margin-bottom: 8px; 
}
.selected-player-addtime__header {
  display: flex;
  margin-bottom: 4px;
}
.selected-player-addtime__item {
  display: flex;
  min-height: 40px;
}
.selected-player-addtimeWrap {
  margin-bottom: 16px;
}
.selected-player-gates__header > div,
.selected-player-gates__item > div,
.selected-player-addtime__header > div, 
.selected-player-addtime__item > div {
  display: flex;
  flex-shrink: 0;
}


.selected-player-addtime input,
.selected-player-gates input {
  border: none;
  height: 40px;
  background: #fff;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  padding: 0 12px;
  width: 100%;
}
.selected-player-addtime input:active,
.selected-player-addtime input:focus,
.selected-player-gates input:active,
.selected-player-gates input:focus {
  border: 1px solid #78909C;
}
.selected-player-gates input.not-active {
  background: #ECEFF1;
  border: 1px solid #CFD8DC;
  box-shadow: none;
  color: #455A64;
  pointer-events: none;
}

.selected-player-gates__item {
  background: #ECEFF1;
  border-radius: 4px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  color: #455A64;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  min-height: 64px;
}

.selected-player-gates__item + .selected-player-gates__item {
  margin-top: 8px;
}

.selected-player-gates__item .btn {
  width: 128px;
  min-width: auto;
  margin-right: 16px;
}

.selected-player-gates .removeTime {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selected-player-addtime {
  background: #ECEFF1;
  padding: 24px 32px;
}

.selected-player-addtime .title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: block;
  color: #455A64;
  margin-bottom: 16px;
}
.selected-player-addtimeWrap .input-field {
  width: 100%;
  min-height: 40px;
  border: none;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
}

.selected-player-addtime__item > div:nth-child(1) {
  flex-direction: column;
}
.selected-player-addtime__item > div:nth-child(1) div:nth-child(2) {
  margin-top: 16px;      
}
@media screen and (max-width: 992px) {
  .modal .btn {
    min-width: 30%;
  }
  .modal .btn-primary {
    min-width: 45%;
  }
}
@media screen and (min-width: 1100px) {
  .panel-competition-result-toolbar .btn.minimal {
    min-width: 157px;
  }
}
.loader {
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader__backdrop {
  background-color: rgba(55, 71, 79, 0.9);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.loader .lds-ellipsis {
  z-index: 55;
}

.button-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.button-wrap .btn:not(:last-child) {margin-right: 8px;}
.is-waiting {opacity: 0.3; pointer-events: none;}
.table-list__row.bg--dark > th {color: #fff}
.table-list__row.bg--dark > th {color: #fff}
.table-list__row.bg--dark > th.table-list__header--first-cell {background-color: var(--color-charcoal-grey);color: #fff;box-shadow: none}
.table-list__row.bg--dark > th.table-list__header--second-cell {background-color: var(--color-charcoal-grey);color: #fff;box-shadow: none;}
.panel-competition-result-toolbar-mobile {margin-top: 16px;}
.panel-competition-result-toolbar-mobile .wrap {margin-bottom: 16px;}
.panel-competition-result-toolbar-mobile .wrap label {display: block; margin-bottom: 8px;}
.panel-competition-result-toolbar-mobile .buttons-wrap {margin-bottom: 12px;display: flex;align-items: center;border-radius: 4px; border: solid 1px var(--color-bluish-grey); overflow: hidden; box-sizing: border-box;}
.panel-competition-result-toolbar-mobile .buttons-wrap .btn {display: flex;align-items: center;justify-content: center;flex-grow: 1;height: 40px;}
.panel-competition-result-toolbar-mobile .buttons-wrap .btn.is-active {background-color: #455A64;}
.panel-competition-result-toolbar-mobile .buttons-wrap .btn.is-active .text {display: block;}
.panel-competition-result-toolbar-mobile .buttons-wrap .btn:not(:first-child) {box-shadow: inset 1px 0px 0px #78909C;}
.panel-competition-result-toolbar-mobile .buttons-wrap .btn .text {display: none; font-size: 14px;margin-left: 8px;}
.panel-competition-result-toolbar-mobile .buttons-wrap .btn .icon {position: relative;}
.panel-competition-result-toolbar-mobile .buttons-wrap .btn .circle {border-radius: 50%; background: linear-gradient(270deg, #FF3D00 0%, #FF1744 99.28%);width: 8px;height: 8px;position: absolute;top: 0;right:0;display: none;}
.panel-competition-result-toolbar-mobile .buttons-wrap .btn .circle.is-active {display: block;}
.panel-competition-result-toolbar-mobile .buttons-content-wrap {display: none; background: #455A64;position: relative;padding: 12px 0;}
.panel-competition-result-toolbar-mobile .buttons-content-wrap.is-active {display: block;}
.panel-competition-result-toolbar-mobile .buttons-content-wrap:before {content: '';position: absolute;top: 0;left: -50%;width: 100%;height: 100%;z-index: 0;background: #455A64;}
.panel-competition-result-toolbar-mobile .buttons-content-wrap:after {content: '';position: absolute;top: 0;right: -50%;width: 100%;height: 100%;z-index: 0;background: #455A64;}
.panel-competition-result-toolbar-mobile .buttons-content-wrap .buttons-content {z-index: 3;position: relative; display: none;}
.panel-competition-result-toolbar-mobile .buttons-content-wrap .buttons-content.is-active {display: block;}
.panel-competition-players-on-the-route {background: #455A64; border-radius: 4px;margin-bottom: 24px;padding: 12px 32px;display: flex;justify-content:space-between;}
.line-through { text-decoration: line-through;}
@media screen and (max-width: 992px) {
  .selected-player-wrap {
    padding: 8px;
    background-color: #fff;
  }
  .selected-player-details {
    padding: 8px;
    margin-bottom: 0;
  }
  .selected-player-gates__header,
  .selected-player-addtime__header {
    display: none;
  }
  .selected-player-details > div:first-child {
    min-width: auto;
    width: 60%;
    margin-bottom: 16px;
  }
  .selected-player-details > div:not(:last-child) {
    margin-right: 0;
  }
  .selected-player-details > div:nth-child(2) {
    width: 40%;
  }
  .selected-player-details > div:nth-child(3) {
    width: 40%;
  }
  .selected-player-details > div:nth-child(4) {
    width: 60%;
  }
  .selected-player-details > div:nth-child(even) {
    text-align: right;
  }
  .selected-player-gates__item {
    flex-wrap: wrap;
    padding: 12px;
    justify-content: space-between;
    align-items: baseline;
  }
  .selected-player-gates__item > div:not(:last-child),
  .selected-player-addtime__item > div:not(:last-child) {
    margin-right: 0;
  }
  .selected-player-gates__item > div {
    flex-direction: column;
  }
  .selected-player-gates__item > div:nth-child(1) {
    order: 1;
    width: 65%;
    text-align: right;
  }
  .selected-player-gates__item > div:nth-child(2) {
    order: 0;
    width: 35%;
    margin-bottom: 12px;
  }
  .selected-player-gates__item > div:nth-child(3) {
    order: 2;
    width: 124px;
  }
  .selected-player-gates__item > div:nth-child(4) {
    order: 3;
    justify-content: flex-end;
  }      
  .selected-player-gates__item .btn {
    width: auto;
    padding: 0 23px;
  }

  .selected-player-gates__item span {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    display: block;
    margin-bottom: 4px;
  }

  .selected-player-addtime input,
  .selected-player-gates input {
    width: auto;
  }
  .selected-player-addtime {
    padding: 24px 16px;
  }
  .selected-player-addtime__item {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .selected-player-addtime__item > div:nth-child(1) {
    width: 100%;
    margin-bottom: 16px;
  }
  .selected-player-addtime__item > div:nth-child(2) {
    min-width: 184px;
  }
  .selected-player-addtime__item > div:nth-child(3) {
    min-width: 88px;
  }
  .selected-player-addtime__item > div:nth-child(3) .btn {
    min-width: 100%;
  }
}
@media screen and (min-width: 992px) {
  .panel-competition-players-on-the-route {margin-top: -32px;}
  .selected-player-wrap {
    padding: 24px 16px;
  }
  .selected-player-details {
    padding: 0 16px;
  }
  .selected-player-gates__header > div:not(:last-child),
  .selected-player-gates__item > div:not(:last-child),
  .selected-player-addtime__header > div:not(:last-child),
  .selected-player-addtime__item > div:not(:last-child) {
    margin-right: 32px;
  } 
  .selected-player-gates__header > div:nth-child(1),
  .selected-player-gates__item > div:nth-child(1) {
    width: calc(100% - 110px - 168px * 2 - 32px * 3);
  }
  .selected-player-addtime__header > div:nth-child(1),
  .selected-player-addtime__item > div:nth-child(1) {
    width: calc(100% - 168px * 2 - 32px * 2);
  }

  .selected-player-gates__header > div:nth-child(2),
  .selected-player-gates__item > div:nth-child(2) {
    width: 110px;
    text-align: right;
  }

  .selected-player-gates__header > div:nth-child(3),
  .selected-player-gates__item > div:nth-child(3),
  .selected-player-addtime__header > div:nth-child(2),
  .selected-player-addtime__item > div:nth-child(2),
  .selected-player-addtime__header > div:nth-child(3),
  .selected-player-addtime__item > div:nth-child(3) {
    width: 168px;
  }

  .selected-player-gates__header > div:nth-child(4), 
  .selected-player-gates__item > div:nth-child(4) {
    align-items: center;
    width: 168px;
  }
}

.shareUrl {
  padding: 24px 32px;
}
.shareUrl a {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #455A64;
}

.shareCarouselWrap {
  overflow: hidden;
  padding: 0 32px 24px 32px;
}

.shareCarousel {
  position: relative;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  display: flex;
  flex-wrap: nowrap;
}

.shareCarouselItem {
  width: 174px;
  padding: 16px;
  border: 1px solid #BDCBD1;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  flex: 0 0 auto;
}

.shareCarouselItem:not(:last-child) {
  margin-right: 24px;
}

.shareCarouselItem.active {
  background: #ECEFF1;
  border: 1px solid #455A64;
}

.shareCarouselItem h4 {
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #455A64;
  margin-bottom: 8px;
}

.shareCarouselItem p {
  font-size: 14px;
  line-height: 20px;
  color: #455A64;
}

.shareContent {
  background-color: #ECEFF1;
  padding: 24px 32px 40px;
}

.shareContent h3 {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #455A64;
}

.shareContentItem {
  display: none;
}
.shareContentItem.active {
  display: block;
}

.radio-field {
  border: none;
  background: transparent;  
}
.radio-field__label:after,
.radio-field__label:before {
  background: transparent;
}
.radio-field input[type='radio']:checked+.radio-field__label:before {
  background-color: #FF3D00;
}
.radio-field input[type='radio']:checked+.radio-field__label:after {
  background-color: #fff;
}
.shareContent label.label {
  display: block;
  margin-top: 24px;
  margin-bottom: 4px;
}
.shareContent .list-header__switch {
  border: none;
}
@media screen and (max-width: 992px) {
.shareUrl {padding: 16px 16px 0 16px;}
.shareCarouselWrap {padding: 24px 0;}
.shareCarouselItem:first-child {margin-left:16px;}
.shareCarouselItem:last-child {margin-right:16px;}
}
.selectedPlayerStatusSelect {
  background: #ECEFF1;
  border-radius: 4px;
  padding: 12px 16px;
  margin-top: -12px;
  margin-left: auto;
  min-width: 152px;
}
</style>